body {
  color: $font-color;
  // #allbox {
  //   overflow: hidden;
  // }

  h1, h2, h3, h4, h5, h6 {
    color: $font-color;
  }
  p, a, span, li, dt, dd, th, td, div {
    font-size: 15px;
    font-weight: 500;
    color: $font-color;
    line-height: 2;
    letter-spacing: .08em;
    @include sp {
      line-height: 1.9;
      letter-spacing: .06em;
    }
  }
  h1, h2, h3, h4, h5, h6, p, a, span, li, dl, dt, dd, th, td, div, figure {
    margin: 0;
    line-break: strict;
  }
  a {
    transition: .3s;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  img {
    max-width: 100%;
  }
}

.pc {
  @include sp {
    display: none !important;
  }
}
.sp {
  @include pc {
    display: none !important;
  }
}

.container {
  max-width: 1060px;
  margin-inline: auto;
  padding-inline: 50px;
  box-sizing: border-box;
  @include pc {
    &.follow_container {
      display: grid;
      grid-template-columns: 1fr 750px;
      align-items: flex-start;
      max-width: calc(50vw + 425px);
      padding-left: 0;
      margin-left: 0;
    }
    .follow_text {
      margin-left: min(3vw, 47px);
      width: min(3.8vw, 48px);
      position: sticky;
      top: 150px;
    }
  }
  @include sp {
    padding-inline: 25px;
  }
}

@keyframes pagetopAnim {
  50% {
    offset-distance: 50%;
    transform: scale(1);
    opacity: 1;
  }
  70% {
    offset-distance: 50%;
    transform: scale(5);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.content_cate_list {
  display: flex;
  li {
    width: 16.66vw;
    a {
      display: grid;
      place-content: center;
      width: 100%;
      height: 90px;
      background-color: #282828;
      color: #fff;
      font-size: clamp(13px, 1vw, 14px);
      &.current, &:hover {
        background-color: $theme-blue;
      }
    }
  }

  @include pc {
    li + li {
      border-left: 1px solid #8E8E8E;
    }
  }

  @include sp {
    flex-wrap: wrap;
    li {
      width: 50%;
      &:nth-of-type(n + 3){
        border-top: 1px solid #8E8E8E;
      }
      &:nth-of-type(2n){
        border-left: 1px solid #8E8E8E;
      }
      a {
        height: 60px;
        font-size: 11px;
      }
    }
  }
}

.text_carousel {
  overflow: hidden;
  position: relative;
  z-index: 1;
  ul {
    animation: textCarousel 15s linear infinite;
    column-gap: 47px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 3330px;
  }
  @include sp {
    ul {
      animation: textCarousel 15s linear infinite;
      column-gap: 22px;
      width: 1535px;
    }
  }
}
@keyframes textCarousel {
  100% {
    transform: translateX(-844px);
  }
}
@include sp {
  @keyframes textCarousel {
    100% {
      transform: translateX(-389px);
    }
  }
}

.arrow {
  display: grid;
  grid-template-columns: 6px 1fr;
  align-items: center;
  transition: $transition-base;
  &::before, &::after {
    content: '';
    display: block;
    background-color: #fff;
  }
  &::before {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }
  &::after {
    height: 1px;
    width: 100%;
  }
  &_rev {
    grid-template-columns: 1fr 6px;
    &::before {
      order: 2;
    }
    &::after {
      order: 1;
    }
  }
}
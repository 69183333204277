.content_header {
  padding: 100px 36px 0;
  box-sizing: border-box;
  h2 {
    position: relative;
    text-align: center;
    strong, small {
      display: block;
      line-height: 1;
    }
    strong {
      @include Jost(500);
      font-size: 60px;
      letter-spacing: .12em;
    }
    small {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: .1em;
    }
  }
  &_img {
    position: relative;
    z-index: 1;
    h2 {
      padding-block: 83px 45px;
      strong, small {
        color: #fff;
      }
    }
    picture {
      display: block;
      height: 550px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    }
    &::before {
      background-color: #F4F5F5;
      height: 1018px;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);
    }
    &::after {
      background: url(/assets/img/common/ttl_bg@2x.jpg) no-repeat center / cover;
      height: 944px;
      clip-path: polygon(0 0, 100% 0, 100% 11%, 0 100%);
    }
  }
  &_text {
    h2 {
      padding-block: 100px 55px;
      strong, small {
        color: $theme-blue;
      }
      @include pc {
        strong {
          font-size: 54px;
        }
      }
    }
  }

  @include sp {
    padding: 67px 16px 0;
    h2 {
      strong {
        font-size: 40px;
        letter-spacing: .08em;
      }
      small {
        margin-top: 8px;
      }
    }
    &_img {
      h2 {
        padding-block: 36px 28px;
      }
      picture {
        height: 240px;
      }
      
      &::before {
        height: 400px;
      }
      &::after {
        background-image: url(/assets/img/common/ttl_bg_sp@2x.jpg);
        height: 394px;
        clip-path: polygon(0 0, 100% 0, 100% 43%, 0 100%);
      }
    }
    &_text {
      h2 {
        padding-block: 40px 30px;
      }
    }
  }
}

.broslink {
  @include broslink();
  &_list {
    @include pc {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 6px;
      max-width: 760px;
      margin: -40px auto 0;
    }
    @include sp {
      margin: 14px 16px 0;
      li + li {
        margin-top: 7px;
      }
    }
  }
}
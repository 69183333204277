body.is--company.is--article-2 {
  .block-img {
    margin-top: 100px;
    ul {
      @include pc {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 36px 40px;
      }
    }
    li {
      line-height: 1;
    }
    p {
      line-height: 1;
      margin-bottom: 13px;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: .14em;
      display: grid;
      grid-template-columns: 24px 1fr;
      align-items: center;
      &::before {
        content: '';
        display: block;
        width: 18px;
        height: 1px;
        background-color: $theme-blue;
      }
    }
  }

  .block-table {
    margin-top: 80px;
    table {
      display: block;
      max-width: 750px;
      margin-inline: auto;
      thead, tbody {
        display: block;
      }
      thead {
        tr {
          background-color: #F4F5F5;
          align-items: center;
          height: 57px;
          padding-block: 0;
        }
        th {
          font-size: 16px;
          font-weight: 700;
          color: $theme-blue;
          letter-spacing: .2em;
        }
      }
      tbody tr:nth-of-type(2n){
        background-color: #F4F5F5;
      }
      tr {
        display: flex;
        justify-content: space-between;
        padding: 18px 35px 19px 27px;
        box-sizing: border-box;
      }
      th, td {
        display: block;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: .18em;
        line-height: 1.3;
      }
    }
  }

  .banner_link_wrap {
    margin-top: 50px;
  }

  @include sp {
    .block-img {
      margin-top: 40px;
      li + li {
        margin-top: 30px;
      }
      p {
        font-size: 18px;
        letter-spacing: .14em;
      }
    }
  
    .block-table {
      margin-top: 40px;
      table {
        thead {
          th {
            font-size: 16px;
            letter-spacing: .2em;
          }
        }
        tr {
          padding-inline: 20px 18px;
        }
        th, td {
          font-size: 14px;
          letter-spacing: .18em;
          line-height: 1.3;
        }
        td {
          white-space: nowrap;
          text-align: right;
        }
      }
    }
  
    .banner_link_wrap {
      margin-top: 40px;
    }
  }
}
body.is--company {
  .rwd002 {
    &-container {
      margin: 0;
      padding: 0;
    }
    &-detail {
      margin: 0;
      padding: 0;
      &__entry {
        max-width: unset;
      }
    }
  }

  &.is--article-1 {
    .anchorlink_list {
      margin-top: 45px;
    }

    .main_img {
      display: block;
      margin-top: 30px;
    }
    .company_table {
      margin-top: 10px;
      display: block;
      tbody {
        display: block;
      }
      th {
        font-weight: 700;
      }
      td {
        font-weight: 500;
      }
      @include pc {
        tr {
          display: grid;
          grid-template-columns: 180px 1fr;
          column-gap: 22px;
          &:last-of-type {
            th, td {
              border-bottom: none;
            }
          }
        }
        th, td {
          font-size: 15px;
          line-height: 1.7;
          padding-block: 20px;
        }
        th {
          border-bottom: 1px solid #BFC6D7;
          padding-left: 10px;
        }
        td {
          padding-left: 30px;
          border-bottom: 1px solid #ECEEF3;
        }
        .history_list {
          li {
            font-size: 15px;
            line-height: 1.7;
            display: grid;
            grid-template-columns: 120px 1fr;
          }
          span {
            font-size: 15px;
            line-height: 1.7;
          }
        }
      }
    }

    .map_area {
      margin-top: 45px;
      .frame_wrap {
        width: 100%;
        height: 370px;
        overflow: hidden;
        iframe {
          height: calc(100% + 360px);
          margin-top: -170px;
          width: 100%;
        }
      }
      .address {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: .08em;
        line-height: 1;
      }
      .link_wrap {
        .map_link {
          border-bottom-color: $theme-blue;
          i, .text {
            color: $theme-blue
          }
          .arrow {
            &::before, &::after {
              background-color: $theme-blue;
            }
          }
        }
      }
      @include pc {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 30px;
      }
    }

    .block-acetec {
      margin-top: 157px;
    }

    .text_carousel {
      margin-top: 120px;
    }

    .block-taisei {
      margin-top: 155px;
      .btn_wrap {
        margin-top: 20px;
      }
      .link_btn {
        @include top_btn();
        margin-inline: auto;
      }
    }

    .banner_link_wrap {
      margin-top: 47px;
    }

    @include sp {
      .anchorlink_list {
        margin-top: 35px;
      }
  
      .main_img {
        margin-top: 20px;
      }
      .company_table {
        tr {
          display: block;
          position: relative;
          padding-block: 15px 13px;
          &:last-of-type {
            &::before, &::after {
              display: none;
            }
          }
          &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            height: 1px;
            left: 0;
            bottom: 0;
          }
          &::before {
            width: 100%;
            background-color: #E3E3E3;
          }
          &::after {
            width: 73px;
            background-color: #BCBCBD;
          }
        }
        th, td {
          font-size: 14px;
          letter-spacing: .08em;
          display: block;
        }
        th {
          line-height: 1;
          font-weight: 700;
        }
        td {
          margin-top: 5px;
          font-weight: 500;
          line-height: 1.5;
        }
        .history_list {
          li {
            display: grid;
            grid-template-columns: 125px 1fr;
            font-size: 14px;
            line-height: 1.5;
          }
          span {
            font-size: 14px;
            line-height: 1.5;
          }
        }
      }
  
      .map_area {
        margin-top: 30px;
        .frame_wrap {
          height: 200px;
        }
        .address {
          font-size: 13px;
          margin-top: 15px;
          text-align: center;
        }
        .link_wrap {
          margin-top: 17px;
          a {
            margin-inline: auto;
          }
        }
      }
  
      .block-acetec {
        margin-top: 100px;
      }
  
      .text_carousel {
        margin-top: 57px;
      }
  
      .block-taisei {
        margin-top: 100px;
      }
  
      .banner_link_wrap {
        margin-top: 35px;
      }
    }
  }
}
.fixed_link {
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-out;
  z-index: 99;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  a {
    position: relative;
    z-index: 1;
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      z-index: -1;
      transition: $transition-base;
    }
    .icon_mail {
      fill: #fff;
      width: 29px;
    }
    span {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: .2em;
      line-height: 1;
    }
    &:hover::after {
      opacity: 0;
    }
  }
  @include pc {
    position: fixed;
    right: 0;
    top: 85px;
    height: 203px;
    a {
      display: grid;
      place-content: center;
      place-items: center;
      row-gap: 15px;
      width: 80px;
      height: 100%;
      span {
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
      }
      &::before {
        background: linear-gradient(#164E9B, #007EB7);
      }
      &::after {
        background: linear-gradient(#007EB7, #168A9B);
      }
    }
  }
  @include sp {
    position: sticky;
    bottom: 0;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      height: 70px;
      span {
        font-size: 15px;
      }
      &::before {
        background: linear-gradient(90deg, #164E9B, #007EB7);
      }
      &::after {
        background: linear-gradient(90deg, #007EB7, #168A9B);
      }
    }
  }
}
@keyframes scrollLine {
  0% {
    transform: scaleY(0);
  }
  70% {
    transform: scaleY(1);
    opacity: .7;
  }
  100% {
    opacity: 0;
  }
}

.is--home {
  background-color: #eee;
  .rwd002-main {
    margin-top: 0;
    overflow: hidden;
  }

  --bg_blue: rgba(22, 78, 155, .7);

  #allbox {
    @include sp {
      padding-top: 0;
    }
  }

  .rwd002-container {
    margin-top: 0;
  }

  .top {
    &_sec_ttl {
      strong, span {
        display: block;
        line-height: 1;
        color: $theme-blue
      }
      strong {
        @include Jost(500);
        font-size: 54px;
        letter-spacing: .12em;
      }
      span {
        font-size: 14px;
        font-weight: 700;
        margin-top: 10px;
        letter-spacing: .1em;
      }
    }
    &_lead {
      font-size: clamp(34px, 3vw, 42px);
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: .03em;
    }
    &_desc {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: .1em;
    }
    &_text_set {
      @include pc {
        max-width: 485px;
      }
      .top_lead {
        margin-top: 25px;
      }
      .top_desc {
        margin-top: 25px;
      }
      .btn_wrap {
        margin-top: 30px;
        a {
          @include top_btn;
        }
      }
    }
  }

  .mainvisual {
    width: 100%;
    height: 100svh;
    position: relative;

    .slide_wrap {
      position: fixed;
      inset: 0;
      z-index: -1;
      &::before {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        z-index: 2;
        pointer-events: auto;
      }
    }

    .mv_slide {
      position: absolute;
      inset: 0;
      .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .swiper-slide-active img,
      .swiper-slide-duplicate-active img,
      .swiper-slide-prev img {
        transform: scale(1.06);
        animation: zoomOut 6s linear 0s 1 normal both;
      }
    }

    .item_wrap {
      position: absolute;
      left: 130px;
      bottom: 25%;
      z-index: 2;
      display: grid;
      row-gap: 100px;
    }

    .catch {
      width: 490px;
      opacity: 0;
      transform: translateY(15px);
    }

    .news_area {
      display: flex;
      justify-content: space-between;
      column-gap: 20px;
      width: 600px;
      border-block: 1px solid rgba(255, 255, 255, .5);
      z-index: 10;
      opacity: 0;
      transform: translateY(15px);
      p, span {
        color: #fff
      }
      .news_wrap {
        flex: 1;
        a {
          display: grid;
          grid-template-columns: 100px 1fr;
          align-items: center;
          place-content: center;
          height: 100%;
          .ttl {
            @include Jost(600);
            font-size: 19px;
            letter-spacing: .1em;
            line-height: 1;
            grid-row: 1 / 3;
          }
          .info {
            display: flex;
            align-items: center;
            line-height: 1;
            transition: $transition-base;
            .date {
              font-size: 11px;
              letter-spacing: .12em;
              @include Mulish(600);
            }
            .cate {
              font-size: 11px;
              font-weight: 500;
              letter-spacing: .12em;
              &::before {
                content: '|';
                margin-inline: 6px;
              }
            }
          }
          .article_ttl {
            font-size: 13px;
            font-weight: 500;
            letter-spacing: .14em;
            line-height: 1;
            transition: $transition-base;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
  
          &:hover {
            .info, .article_ttl {
              opacity: .7;
            }
          }
        }
      }
      .more_wrap {
        a {
          display: flex;
          align-items: center;
          column-gap: 5px;
          height: 60px;
          &:hover img {
            transform: translateX(5px);
          }
        }
        span {
          @include Mulish(700);
          font-size: 12px;
          letter-spacing: .08em;
        }
        img {
          width: 12px;
          transition: $transition-base;
        }
      }
    }

    .video_wrap {
      position: absolute;
      aspect-ratio: 700 / 459;
      width: 100%;
      height: auto;
      overflow: hidden;
      clip-path: polygon(0 43%, 33% 62%, 100% 0, 100% 100%, 33% 62%, 0 92%);
      left: 0;
      bottom: 0;
      transform: translateY(38%);
      z-index: 1;
      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
      }
      &::before {
        left: 0;
        width: 33%;
        background-color: #164E9B;
        opacity: .5;
      }
      &::after {
        right: 0;
        width: 67%;
        background-color: #007EB7;
        opacity: .56;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .scrolldown {
      right: 42px;
      bottom: 95px;
    }

    &.loaded {
      .catch, .news_area {
        opacity: 1;
        transform: translateY(0);
      }
      .catch {
        transition: opacity 1s ease-out .4s, transform 1s ease-out .4s;
      }
      .news_area {
        transition: opacity 1s ease-out .7s, transform 1s ease-out .7s;
      }
    }

  }

  @keyframes zoomOut {
    from { transform: scale(1.1);}
    to   { transform: scale(1);}
  }

  .block-about {
    padding-top: 220px;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 220px;
      background-color: #fff;
      left: 0;
      top: 0;
    }
    .simbol_wrap {
      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        width: 80vw;
        height: 100%;
        background-color: #fff;
        top: 0;
        z-index: -1;
      }
      &::before {
        right: calc(100% - 1px);
      }
      &::after {
        left: 100%;
      }
      .color_bg {
        position: absolute;
        inset: 0;
        border: 1px solid #fff;
        box-sizing: border-box;
        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          bottom: 0;
        }
        &::before {
          left: 0;
          width: 100%;
          height: 100%;
          clip-path: polygon(0 0, 100% 0, 28% 100%, 0 100%);
          background-color: #007EB7;
          opacity: .56;
        }
        &::after {
          right: 0;
          width: 70%;
          height: 95%;
          clip-path: polygon(100% 0, 100% 100%, 0 100%);
          background-color: $theme-blue;
          opacity: .5;
        }
      }
      .rect {
        fill: #fff;
      }
      .simbol {
        fill: #000;
      }
      #bg_wh {
        mask:url(#mask);
        fill: #fff;
        width: 100%;
        height: 100%;
      }
    }
    .top_text_set {
      z-index: 1;
    }
    @include pc {
      .container {
        display: flex;
        align-items: center;
        height: 600px;
        padding-left: clamp(364px, 45%, 525px);
      }
      .simbol_wrap {
        position: absolute;
        width: 675px;
        right: min(61%, calc(50% + 125px));
        bottom: 0;
      }
    }
  }

  .block-service {
    background-color: var(--bg_blue);
    .container {
      position: relative;
      z-index: 2;
    }
    @include pc {
      padding-block: 135px 90px;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        background-color: #fff;
        width: 2384px;
        height: auto;
        aspect-ratio: 68 / 39;
        overflow: hidden;
        clip-path: polygon(0 0, 100% 0, 0 100%);
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: 1;
      }
      .container {
        display: grid;
        grid-template-columns: 1fr 50%;
        column-gap: 30px;
        max-width: calc(50% + 678px);
        padding-right: 0;
        margin-right: 0;
      }
      .top_text_set {
        margin: 3vw 0 0 auto;
      }
    }
  }

  .block-company {
    position: relative;
    background-color: var(--bg_blue);
    strong, span, p {
      color: #fff;
    }
    .container {
      position: relative;
      z-index: 2;
    }

    .top_sec_ttl {
      text-align: center;
    }
    .top_desc {
      margin-top: 20px;
      @include pc {
        text-align: center;
      }
    }

    .link_list {
      margin-top: 25px;
      @include pc {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      a {
        display: grid;
        place-content: center;
        place-items: center;
        height: 362px;
        position: relative;
        padding-top: 21px;
        box-sizing: border-box;
        row-gap: 5px;
        z-index: 1;
        picture {
          position: absolute;
          inset: 0;
          overflow: hidden;
          z-index: -1;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: $transition-base;
          }
        }
        strong {
          @include Jost(500);
          font-size: 39px;
          letter-spacing: .14em;
          line-height: 1;
        }
        span {
          font-size: 14px;
          font-weight: 700;
          line-height: 1;
          letter-spacing: .1em;
        }
        .arrow {
          position: absolute;
          right: 15px;
          bottom: 19px;
          width: 26px;
        }
        
        &:hover {
          img {
            transform: scale(1.07);
          }
          .arrow {
            transform: translateX(5px);
          }
        }
      }
    }

    .text_carousel {
      margin-top: -30px;
    }
  }

  .block-news {
    padding-block: 235px 85px;
    position: relative;
    background-color: var(--bg_blue);
    .news_bg {
      position: absolute;
      width: 3000px;
      height: auto;
      aspect-ratio: 300 / 172;
      overflow: hidden;
      background-color: #fff;
      clip-path: polygon(100% 0, 100% 100%, 0 100%);
      left: 50%;
      top: -1014px;
      transform: translateX(-50%);
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: auto;
        aspect-ratio: 3000 / 1687;
        overflow: hidden;
        background-color: #F4F5F5;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 31%);
        left: 0;
        top: 0;
      }
    }
    .news_list {
      overflow: hidden;
      li {
        + li {
          border-top: 1px solid #B7B8B8;
        }
      }
      a {
        padding: 24px 40px 20px 0;
        display: block;
        overflow: hidden;
        .info {
          display: flex;
          align-items: center;
          column-gap: 20px;
          line-height: 1;
          .date {
            @include Roboto(400);
            font-size: 13px;
            letter-spacing: .08em;
            line-height: 1;
            transition: $transition-base;
          }
          .cate {
            display: grid;
            place-content: center;
            min-width: 84px;
            height: 21px;
            padding-inline: 15px;
            box-sizing: border-box;
            background-color: $font-color;
            font-size: 11px;
            font-weight: 700;
            letter-spacing: .18em;
            color: #fff;
            transition: $transition-base;
          }
        }
        .ttl {
          font-size: 15px;
          line-height: 1.6;
          font-weight: 500;
          letter-spacing: .08em;
          transition: $transition-base;
          margin-top: 9px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:hover {
          .date, .ttl {
            color: $theme-blue;
          }
          .info .cate {
            background-color: $theme-blue;
          }
        }
      }
    }

    .btn_wrap {
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 117px;
        padding-bottom: 10px;
        border-bottom: 1px solid $theme-blue;
        margin-inline: auto 0;
        .text {
          @include Mulish(600);
          font-size: 14px;
          letter-spacing: .14em;
          line-height: 1;
          color: $theme-blue;
          transition: $transition-base;
        }
        .arrow {
          width: 25px;
          &::before, &::after {
            background-color: $theme-blue;
          }
        }
        &:hover {
          border-block-color: #0084B1;
          .text {
            color: #0084B1;
          }
          .arrow {
            transform: translateX(5px);
            &::before, &::after {
              background-color: #0084B1;
            }
          }
        }
      }
    }

    @include pc {
      position: relative;
      .container {
        display: grid;
        grid-template-columns: 240px 1fr;
        position: relative;
      }
      .news_list {
        margin-top: -25px;
      }
      .btn_wrap {
        grid-column: span 2;
        a {
          margin-inline: auto 0;
        }
      }
    }
  }

  @include sp {
    .top {
      &_sec_ttl {
        text-align: center;
        strong {
          font-size: 40px;
          letter-spacing: .08em;
        }
        span {
          margin-top: 8px;
        }
      }
      &_lead {
        font-size: 23px;
        line-height: 1.4;
        letter-spacing: .08em;
        text-align: center;
      }
      &_desc {
        font-size: 14px;
        letter-spacing: .06em;
        line-height: 1.7;
      }
      &_text_set {
        .top_lead {
          margin-top: 15px;
        }
        .top_desc {
          margin-top: 17px;
        }
        .btn_wrap {
          margin-top: 20px;
        }
      }
    }

    .mainvisual {
      .item_wrap {
        left: 25px;
        bottom: 60px;
        row-gap: 44px;
        max-width: calc(100% - 50px);
      }
  
      .catch {
        width: 94%;
      }
  
      .news_area {
        width: 100%;
        .news_wrap {
          a {
            grid-template-columns: 67px 1fr;
            row-gap: 3px;
            .ttl {
              font-size: 15px;
              grid-row: unset;
            }
            .info {
              display: flex;
              align-items: center;
              line-height: 1;
              transition: $transition-base;
              .date {
                font-size: 11px;
                letter-spacing: .12em;
                @include Mulish(600);
              }
              .cate {
                font-size: 11px;
                font-weight: 500;
                letter-spacing: .12em;
                &::before {
                  content: '|';
                  margin-inline: 6px;
                }
              }
            }
            .article_ttl {
              grid-column: span 2;
              font-size: 13px;
              letter-spacing: .14em;
            }
          }
        }
        .more_wrap {
          span {
            display: none;
          }
        }
      }
    }
  
    .block-about {
      padding-top: 89px;
      border-bottom: 1px solid #D6D6D6;
      &::before {
        height: 89px;
      }
      .simbol_wrap {
        width: 217px;
        margin-inline: auto;
        position: relative;
        right: 25px;
      }
      .top_text_set {
        background-color: #fff;
        padding: 20px 25px 52px;
        margin-inline: -25px;
      }
    }

    .block-service {
      position: relative;
      background-color: var(--bg_blue);
      .top_text_set {
        padding: 50px 25px 0;
        margin-inline: -25px;
        background-color: #fff;
      }
      picture {
        display: block;
        position: relative;
        padding-top: 55px;
        margin-right: -25px;
        &::before {
          content: '';
          display: block;
          position: absolute;
          background-color: #fff;
          width: 300vw;
          height: auto;
          aspect-ratio: 35 / 20;
          overflow: hidden;
          clip-path: polygon(0 0, 100% 0, 0 100%);
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          z-index: -1;
        }
      }
    }

    .block-company {
      padding-top: 30px;
      .top_desc {
        margin-top: 30px;
      }
  
      .link_list {
        margin-top: 25px;
        li + li {
          margin-top: 3px;
        }
        a {
          height: 162px;
          padding-top: 11px;
          row-gap: 5px;
          strong {
            font-size: 27px;
          }
          span {
            font-size: 13px;
          }
          .arrow {
            right: 10px;
            bottom: 10px;
            transform-origin: right bottom;
            transform: scale(.7);
          }
        }
      }

      .text_carousel {
        margin-top: 27px;
      }
    }
  
    .block-news {
      padding-block: 96px 100px;
      .news_bg {
        width: 3000px;
        aspect-ratio: 300 / 172;
        top: -910px;
        &::before {
          top: -164px;
        }
      }

      .top_sec_ttl {
        text-align: center;
      }

      .news_list {
        margin-top: 17px;
        a {
          padding: 20px 0 18px;
          .info {
            column-gap: 15px;
          }
          .ttl {
            font-size: 14px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            white-space: wrap;
          }
        }
      }
  
      .btn_wrap {
        margin-top: 24px;
      }
    }
  }
}
.scrolldown {
  position: absolute;
  z-index: 1;
  .text {
    font-size: 10px;
    @include Roboto(500);
    letter-spacing: .2em;
    color: #fff;
    line-height: 1;
    transform-origin: right center;
    transform: rotate(90deg);
    display: block;
  }
  .path_wrap {
    width: 6.5px;
    position: relative;
    margin-inline: auto 0;
    transform: rotate(180deg);
    .point {
      display: block;
      position: absolute;
      width: 6px;
      height: auto;
      aspect-ratio: 1 / 1;
      overflow: hidden;
      border-radius: 50%;
      background-color: #fff;
      right: 0;
      top: 0;
      box-shadow: 0 0 100px #fff, 0 0 100px #fff, 0 0 100px #fff, 0 0 100px #fff, 0 0 100px #fff, 0 0 100px #fff, 0 0 100px #fff, 0 0 100px #fff;
      offset-path: path("M.5 118 .5 66.5 6 51.5 6 0z");
      offset-rotate: auto 90deg;
      animation: pagetopAnim 2s linear infinite;
    }
  }
}
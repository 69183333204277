footer {
  position: relative;
  background: url(/assets/img/common/footer_bg@2x.jpg) no-repeat center / cover;

  .container {
    padding-block: 64px 40px;
    p, a, a:hover, a:visited, li, dt, dd, span {
      color: #fff;
    }
    @include pc {
      max-width: 1100px;
    }
  }
  
  .contact_wrap {
    dt, dd, a, span {
      line-height: 1;
    }

    .ttl_wrap {
      dt {
        @include Jost(500);
        font-size: 45px;
        letter-spacing: .12em;
      }
      dd {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: .1em;
        margin-top: 6px;
      }
    }
    .tel_wrap {
      padding-left: 40px;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        background: url(/assets/img/common/icon_tel.svg) no-repeat 0 / contain;
        left: 0;
        top: 0;
      }
      dt {
        line-height: 1;
        a {
          @include Roboto(500);
          font-size: 33px;
          letter-spacing: .08em;
          line-height: 1;
        }
      }
      dd {
        font-size: 12px;
        letter-spacing: .14em;
        line-height: 1;
        margin-top: 6px;
        position: relative;
        left: -4px;
      }
    }
    .btn_wrap {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 288px;
        height: 66px;
        border: 1px solid #fff;
        border-radius: 33px;
        column-gap: 7px;
        .icon_mail {
          width: 23px;
          fill: #fff;
        }
        span {
          font-size: 17px;
          font-weight: 700;
          letter-spacing: .2em;
          transition: $transition-base;
        }
        &:hover {
          background-color: #fff;
          .icon_mail {
            fill: $theme-blue;
          }
          span {
            color: $theme-blue;
          }
        }
      }
    }

    @include pc {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      @include max(1000px){
        .ttl_wrap {
          dt {
            font-size: 36px;
          }
        }
        .tel_wrap {
          dt {
            line-height: 1;
            a {
              font-size: 30px;
            }
          }
        }
        .btn_wrap {
          a {
            width: 250px;
            padding-right: 5px;
            .jp {
              font-size: 14px;
            }
          }
        }  
      }
    }
  }

  .main_wrap {
    padding-top: 54px;
    margin-top: 54px;
    border-top: 1px solid #C8D3ED;

    .info_wrap {
      .logo {
        max-width: 310px;
        a:hover {
          opacity: .7;
        }
      }

      dl {
        margin-top: 24px;
        dt {
          line-height: 1;
          margin-bottom: 16px;
          a {
            font-size: 17px;
            font-weight: 700;
            line-height: 1;
            letter-spacing: .1em;
            &:hover {
              opacity: .7;
            }
          }
        }
        dd {
          font-size: 12px;
          line-height: 1;
          letter-spacing: .14em;
          a {
            font-size: 12px;
            letter-spacing: .14em;
            line-height: 1;
          }
          + dd {
            margin-top: 6px;
          }
        }
      }
      .btn_wrap {
        margin-top: 24px;
      }
    }

    nav {
      ul {
        display: flex;
        flex-wrap: wrap;
        li, a {
          line-height: 1;
        }
        a {
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #fff;
            left: 0;
            bottom: -3px;
            transition: transform .2s;
            transform-origin: right;
            transform: scaleX(0);
          }
          &:hover::before {
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      }
      .main_nav {
        row-gap: 15px;
        li {
          + li::before {
            content: '/';
            margin-inline: 12px 14px;
            position: relative;
            top: 1px;
          }
        }        
        a {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: .2em;
        }
      }
      .other_nav {
        margin-top: 36px;
        li {
          font-size: 10px;
          letter-spacing: .2em;
          + li::before {
            content: '/';
            margin-inline: 7px 8px;
            position: relative;
            top: 2px;
          }
        }
        a {
          @include Mulish(600);
          font-size: 12px;
          letter-spacing: .14em;
        }
      }
    }

    .copy {
      font-size: 10px;
      line-height: 1;
      letter-spacing: .19em;
      margin-top: 90px;
      opacity: .5;
    }

    @include pc {
      display: grid;
      grid-template-columns: 330px 1fr;
      column-gap: min(7%, 100px);
      nav {
        max-inline-size: max-content;
        max-width: 420px;
        margin-inline: auto 0;
        .main_nav li:nth-of-type(5)::before {
          display: none;
        }
      }
      .copy {
        grid-column: 1 / 3;
      }
    }
  }

  .login {
    width: 13px;
    position: absolute;
    right: 20px;
    bottom: 34px;
    line-height: 1;
    a {
      line-height: 1;
      &:hover {
        opacity: .7;
      }
    }
  }

  @include sp {
    .container {
      padding-block: 64px 25px;
    }
    
    .contact_wrap {
      .ttl_wrap {
        text-align: center;
        dt {
          font-size: 40px;
          letter-spacing: .08em;
        }
      }
      .tel_wrap {
        margin: 20px auto 0;
        max-inline-size: max-content;
        dt {
          a {
            font-size: 30px;
          }
        }
        dd {
          margin-top: 2px;
          font-size: 11px;
        }
      }
      .btn_wrap {
        margin-top: 22px;
        a {
          margin-inline: auto;
          width: 260px;
          height: 52px;
          border-radius: 33px;
          .icon_mail {
            width: 21px;
          }
          span {
            font-size: 15px;
          }
        }
      }
    }
  
    .main_wrap {
      padding-top: 32px;
      margin-top: 40px;

      .info_wrap {
        max-inline-size: max-content;
        margin-inline: auto;
        .logo {
          width: 212px;
          margin-inline: auto;
        }
        .btn_wrap {
          margin-top: 24px;
        }
      }
  
      nav {
        margin: 42px auto 0;
        max-width: 370px;
        .main_nav {
          row-gap: 12px;
          li:nth-of-type(4)::before, li:nth-of-type(7)::before {
            display: none;
          }
        }
        .other_nav {
          margin-top: 32px;
        }
      }
  
      .copy {
        line-height: 1.8;
        margin: 50px auto 0;
        max-width: 265px;
        text-align: center;
      }

    }
  
    .login {
      right: 22px;
      bottom: 28px;
    }
  }
}

.pagetop {
  position: absolute;
  right: 0;
  a {
    display: grid;
    line-height: 1;
    background-color: $theme-blue;
    .text {
      font-size: 11px;
      @include Canta(700);
      letter-spacing: .18em;
      color: #fff;
      line-height: 1;
    }
    &:hover {
      background-color: #0084B1;
    }
  }
  @include pc {
    top: -42px;
    a {
      justify-content: center;
      align-items: flex-start;
      padding-top: 18px;
      padding-left: 9px;
      box-sizing: border-box;
      width: 58px;
      height: 210px;
      position: relative;
      .path_wrap {
        width: 7.5px;
        position: relative;
        margin-inline: auto 0;
        transform: rotate(180deg);
        .point {
          display: block;
          position: absolute;
          width: 6px;
          height: auto;
          aspect-ratio: 1 / 1;
          overflow: hidden;
          border-radius: 50%;
          background-color: #fff;
          right: 1px;
          top: 0;
          offset-path: path("M7 0 6.5 30.51 1 45.51 .5 76.01z");
          offset-rotate: auto 90deg;
          animation: pagetopAnim 2s linear infinite;
        }
      }
      .text {
        white-space: nowrap;
        transform-origin: right center;
        transform: rotate(90deg);
        display: block;
        position: absolute;
        right: 28px;
        bottom: 21px;
      }
    }
  }

  @include sp {
    left: 0;
    right: 0;
    top: -24px;
    margin: auto;
    a {
      margin: auto;
      position: relative;
      place-content: center;
      width: 215px;
      height: 54px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        right: 15px;
        transform: rotate(45deg);
        top: 3px;
        bottom: 0;
        margin: auto;
      }
      .text {
        font-size: 12px;
      }
    }
  }
}
body.is--aboutus {
  .rwd002 {
    &-container {
      margin: 0;
      padding: 0;
    }
    &-detail {
      margin: 0;
      padding: 0;
      &__entry {
        max-width: unset;
      }
    }
  }

  .block-lead {
    margin-top: 80px;
    h3 {
      text-align: center;
      position: relative;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: .1em;
      padding-bottom: 23px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 90px;
        height: 2px;
        background-color: $theme-blue;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .lead_text {
      margin-top: 40px;
      @include pc {
        text-align: center;
        line-height: 2.1;
      }
    }
    picture {
      display: block;
    }
    .pic1 {
      margin-top: 54px;
    }
    .pic2 {
      margin: 64px auto 0;
      max-width: 518px;
    }
    .desc {
      margin: 56px auto 0;
      max-width: 750px;
      @include pc {
        line-height: 2.1;
      }
    }
  }

  .block-point {
    margin-top: 73px;
    picture {
      display: block;
    }
    .num {
      @include Jost(600);
      line-height: 1;
      max-inline-size: max-content;
      padding-bottom: 10px;
      border-bottom: 2px solid #fff;
      font-size: 21px;
      letter-spacing: .14em;
    }
    h3 {
      font-size: 30px;
      font-weight: 700;
      letter-spacing: .14em;
      line-height: 1.5;
    }
    @include pc {
      height: calc(100svh + 1700px);
      .scroll_wrap {
        position: sticky;
        top: 0;
        width: 100%;
        height: 100svh;
      }
      .point_card {
        position: absolute;
        inset: 0;
        transition: 1.2s;
        z-index: 1;
        &.point01 { z-index: 3;}
        &.point02 { z-index: 2;}
        &.point03 { z-index: 1;}

        p, h3 {
          color: #fff;
        }
        picture {
          position: absolute;
          inset: 0;
          z-index: -1;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .num {
          position: absolute;
          left: 86px;
          top: 136px;
        }
        .body {
          position: absolute;
          width: min(90%, 1100px);
          inset: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: auto;
        }
        .desc {
          line-height: 2.1;
          max-width: 512px;
        }
        .num, h3, .desc {
          opacity: 0;
          transform: translateY(10px);
        }
      }
      &.pt2 .point01 { opacity: 0;}
      &.pt3 .point02 { opacity: 0;}
      &.active .point_card {
        .num, h3, .desc {
          opacity: 1;
          transform: translateY(0);
          transition-duration: .6s;
        }
        h3 {
          transition-delay: .2s;
        }
        .desc {
          transition-delay: .4s;
        }
      }

      .scrolldown {
        right: 20px;
        bottom: 24px;
        z-index: 3;
      }
    }
  }

  .banner_link_wrap {
    margin-top: 78px;
  }

  @include sp {
    .block-lead {
      margin-top: 23px;
      h3 {
        font-size: 20px;
        line-height: 1.5;
        padding-bottom: 15px;
      }
      .lead_text {
        margin-top: 18px;
      }
      .pic1 {
        margin: 32px -10px 0;
        display: flex;
        justify-content: center;
      }
      .pic2 {
        margin-top: 34px;
      }
      .desc {
        margin-top: 45px;
      }
    }
  
    .block-point {
      margin-top: 65px;
      .point_card {
        margin-inline: 25px;
        + .point_card {
          margin-top: 40px;
        }
      }
      picture {
        margin-inline: -25px;
        img{
          width: 100%;
        }
      }
      .num {
        padding-bottom: 10px;
        border-color: $font-color;
        font-size: 18px;
        margin-top: 25px;
      }
      h3 {
        font-size: 24px;
        letter-spacing: .14em;
        line-height: 1.4;
        margin-top: 26px;
      }
      .desc {
        margin-top: 20px;
      }
    }
  
    .banner_link_wrap {
      margin-top: 46px;
    }
  }
}
$gnav-height-xs: 48px;

.rwd002-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  z-index: 100;
  position: relative;
  position: absolute;
  left: 0;
  top: 0;

  @include media-up(lg) {
    padding-left: clamp(20px, 5vw, 50px);
  }

  @include media-down(lg) {
    height: 67px;
    overflow: hidden;
    padding-left: 27px;
  }

  &__logo {
    flex-grow: 1;
    margin: 0;
    max-height: 100%;
    width: 204px;
    img {
      width: 204px;
    }
    &-link {
      &:hover,
      &:focus {
        text-decoration: none;
        opacity: .7;
      }
    }
    @include sp {
      width: 185px;
      img {
        width: 185px;
      }
    }
  }

  // PC縮小時
  @include pc {
    @include max(1000px){
      padding-left: 15px
    }
  }
}

.rwd002-header__drawerBtn {
  @extend .btn;
  width: 67px;
  height: 67px;
  position: relative;
  border-radius: 2px;
  background: none !important;
  z-index: 101;
  padding: 0;
  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: linear-gradient(90deg, #168A9B, #009BB7);
    transition: $transition-base;
    z-index: -1;
  }
  svg {
    width: 19px;
  }

  &:focus {
    box-shadow: none;
  }

  @include media-up(lg) {
    display: none;
  }

  &__bars {
    stroke: #fff;
  }

  &__bar01,
  &__bar02,
  &__bar03 {
    transform-origin: center;
    transition: $transition-base;
  }

  &.is-active {
    &::before {
      opacity: 0;
    }
    .rwd002-header__drawerBtn__bars {
      stroke: #002980;
    }
  }
  .is-active &__bar01 {
    transform: rotate(135deg) translateY(50%);
  }

  .is-active &__bar02 {
    transform: rotate(45deg);
    opacity: 0;
  }

  .is-active &__bar03 {
    transform: rotate(225deg) translateY(-50%);
  }
}

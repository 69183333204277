body.is--service {
  .rwd002 {
    &-container {
      margin: 0;
      padding: 0;
    }
    &-detail {
      margin: 0;
      padding: 0;
      &__entry {
        max-width: unset;
      }
    }
  }

  .follow_block {
    background-color: #F4F5F5;
    position: relative;
    padding-bottom: 86px;
    z-index: 1;
    margin-bottom: 90px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 380px;
      background-color: #fff;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
  @include pc {
    @include min(1000px){
      .follow_container {
        grid-template-columns: 1fr 880px;
        max-width: calc(50vw + 490px);
      }
      .follow_text {
        margin-inline: auto 20px;
      }
    }
    @include min(1100px){
      .follow_container {
        grid-template-columns: 1fr 960px;
        max-width: calc(50vw + 530px);
      }
    }
    @include min(1150px){
      .follow_text {
        margin-left: min(3vw, 47px);
        width: min(3.8vw, 48px);
      }
    }
  }

  .anchorlink_list {
    margin-top: 90px;
  }

  .main_img {
    margin-top: 40px;
    display: block;
  }

  .desc {
    max-width: 750px;
    margin: 38px auto 0;
  }

  .point_wrap {
    border: 1px solid #B0B1B1;
    box-sizing: border-box;
    position: relative;
    padding: 38px 40px;
    dt {
      @include Canta(700);
      color: $theme-blue;
      font-size: 22px;
      letter-spacing: .14em;
      background-color: #F4F5F5;
      width: 156px;
      height: 30px;
      display: grid;
      place-content: center;
      position: absolute;
      left: 0;
      right: 0;
      top: -15px;
      margin: auto;
    }
    dd {
      line-height: 1.9;
      max-width: 750px;
      margin-inline: auto;
      strong {
        font-weight: 500;
        background: linear-gradient(#FFE663, #FFE663);
        padding: 5px;
      }
    }
  }

  .block-board {
    margin-top: 157px;
    @include pc {
      &::before {
        height: 500px;
      }
    }
    .lead_text {
      margin-block: 42px 54px;
      font-size: 19px;
      font-weight: 700;
      line-height: 1.7;
      @include pc {
        text-align: center;
      }
    }
    .board_list {
      margin-top: 56px;
      .card {
        background-color: #fff;
        padding: 45px 45px;
        box-sizing: border-box;
        .card_ttl {
          display: grid;
          grid-template-columns: 23px 1fr;
          align-items: center;
          font-size: 17px;
          font-weight: 700;
          letter-spacing: .14em;
          line-height: 1.2;
          &::before {
            content: '';
            display: block;
            width: 18px;
            height: 1px;
            background-color: $theme-blue;
          }
        }
        .main_list {
          > li + li {
            margin-top: 15px;
          }
          .main_item {
            display: grid;
            grid-template-columns: 20px 1fr;
            align-items: baseline;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: .08em;
            &::before {
              content: '';
              display: block;
              width: 10px;
              height: 10px;
              background-color: $theme-blue;
              border-radius: 50%;
              position: relative;
              bottom: 2px;
            }
          }
        }
        .sub_list {
          margin-top: 10px;
          margin-left: 18px;
          li {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.7;
            letter-spacing: .1em;
          }
        }
      }
      @include pc {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        .card:has(.main_list){
          grid-column: span 2;
          display: grid;
          grid-template-columns: 200px 1fr;
          align-items: flex-start;
        }
      }
    }
    .point_wrap {
      margin-top: 60px;
    }
  }

  .block-material {
    .material_list {
      margin: 34px auto 0;
      max-width: 750px;
      li {
        display: grid;
        grid-template-columns: 18px 1fr;
        align-items: baseline;
        + li {
          margin-top: 3px;
        }
        &::before {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $theme-blue;
        }
      }
    }
  }

  .block-sales {
    .point_wrap {
      margin-top: 70px;
    }
  }

  .block-step {
    .note {
      margin-top: 10px;
      line-height: 1;
      text-align: right;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: .1em;
    }
    .step_list {
      margin-top: 15px;
      padding: 45px 40px;
      box-sizing: border-box;
      box-shadow: 0 0 10px rgba(96, 96, 96, 0.3);
      li {
        display: grid;
        grid-template-columns: 90px 1fr;
        align-items: baseline;
        max-width: 750px;
        margin-inline: auto;
        position: relative;
        &:last-of-type {
          &::before, &::after {
            display: none;
          }
        }
        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
        }
        &::before {
          width: 1px;
          height: calc(100% - 32px);
          background-color: #BFC6D7;
          left: 20px;
          bottom: -14px;
        }
        &::after {
          width: 5px;
          height: 5px;
          border-left: 1px solid #BFC6D7;
          border-bottom: 1px solid #BFC6D7;
          box-sizing: border-box;
          transform: rotate(-45deg);
          left: 18px;
          bottom: -14px;
        }
        + li .text {
          border-top: 1px solid #ECEEF3;
        }
      }
      .num {
        @include Jost(500);
        font-size: 12px;
        letter-spacing: .16em;
        color: $theme-blue;
      }
      .text {
        padding-block: 15px;
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }

  @include sp {
    .follow_block {
      padding-bottom: 60px;
      margin-bottom: 65px;
      &::before {
        height: 154px;
      }
    }
  
    .anchorlink_list {
      margin-top: 22px;
    }
  
    .main_img {
      margin-top: 25px;
    }
  
    .desc {
      margin-top: 20px;
    }
  
    .point_wrap {
      padding: 32px 32px 25px;
      margin-inline: -10px;
      dt {
        font-size: 18px;
        width: 110px;
      }
      dd {
        strong {
          padding: 5px;
        }
      }
    }
  
    .block-board {
      margin-top: 100px;
      &::before {
        height: 256px;
      }
      .lead_text {
        margin-block: 20px 0;
        font-size: 14px;
      }
      .board_list {
        margin: 42px -10px 0;
        .card {
          padding: 32px 25px;
          + .card {
            margin-top: 15px;
          }
          .card_ttl {
            grid-template-columns: 23px 1fr;
            font-size: 17px;
          }
          .main_list {
            margin-top: 24px;
            > li + li {
              margin-top: 15px;
            }
            .main_item {
              grid-template-columns: 18px 1fr;
              font-size: 16px;
              &::before {
                bottom: 1px;
              }
            }
          }
          .sub_list {
            margin-top: 8px;
            margin-left: 18px;
            li {
              line-height: 1.4;
            }
          }
        }
      }
      .point_wrap {
        margin-top: 45px;
      }
    }
  
    .block-material {
      .material_list {
        margin-top: 20px;
      }
    }
  
    .block-sales {
      .point_wrap {
        margin-top: 45px;
      }
    }
  
    .block-step {
      .line_ttl strong {
        font-size: 22px;
      }
      .note {
        margin-top: 23px;
        text-align: center;
      }
      .step_list {
        margin: 16px -25px 0;
        padding: 40px 25px 30px;
        li {
          grid-template-columns: 63px 1fr;
        }

        .text {
          padding-block: 15px;
          line-height: 1.4;
        }
      }
    }
  }
}
@mixin c-module-title() {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.75em;
  font-weight: normal;
  letter-spacing: 0.22em;
  text-indent: 0.22em;
  font-size: $h2-font-size;
  @include media-down(lg) {
    font-size: 1.875rem;
  }
  @include media-down(md) {
    font-size: $h4-font-size;
  }

  span {
    font-family: "Roboto", $font-family-sans-serif;
    font-weight: $font-weight-normal;
  }

  &:after{
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    width: 82px;
    height: 1px;
    margin: auto;
    background: $primary;
  }
}

.sec_ttl {
  text-align: center;
  span, strong {
    display: block;
    line-height: 1;
  }
  span {
    @include Roboto(500);
    font-size: 13px;
    color: $theme-blue;
  }
  strong {
    font-size: 30px;
    margin-top: 10px;
  }
  @include sp {
    strong {
      font-size: 21px;
      letter-spacing: .12em;
      margin-top: 9px;
    }
  }
}

.line_ttl {
  position: relative;
  text-align: center;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 80px;
    background-color: #D6D6D6;
    left: 0;
    right: 0;
    top: -90px;
    margin: auto;
  }
  strong, span {
    display: block;
  }
  strong {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.3;
  }
  span {
    margin-top: 10px;
    line-height: 1;
    @include Jost(700);
    font-size: 13px;
    letter-spacing: .16em;
    color: $theme-blue;
  }
  
  @include sp {
    &::before {
      height: 53px;
      top: -67px;
    }
    strong {
      font-size: 26px;
      letter-spacing: .14em;
    }
    span {
      font-size: 12px;
      margin-top: 5px;
    }
  }
}
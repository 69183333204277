.rwd002-gnav {
  @include media-up(lg) {
    margin-left: map-get($spacers, 3);
  }

  @include media-down(lg) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 100;
    background-color: transparent;
    transform: translateX(100%);
    transition: background-color 0.2s ease-in-out, transform 0s 0.5s;
  }

  &.is-active {
    // メニューが開いたときのオーバーレイ
    @include media-down(lg) {
      background-color: fade-out($black, 0.2);
      transform: translateX(0%);
      transition: background-color 0.2s ease-in-out, transform 0s 0s;
    }
  }

  &__inner {
    // 格納メニュー
    @include media-down(lg) {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding: 76px map-get($spacers, 2) map-get($spacers, 2);
      width: calc(100vw - map-get($spacers, 5));
      transform: translateX(100%);
      transition: $transition-base;
      background-color: $white;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }

    @include pc {
      display: flex;
      align-items: center;
      column-gap: 30px;
    }
  }

  .is-active &__inner {
    @include media-down(lg) {
      transform: translateX(0%);
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    @include media-up(lg) {
      margin-right: -0.75em;
      align-items: stretch;
      flex-direction: row;
    }
  }

  &__contact {
    width: 188px;
    position: relative;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      box-sizing: border-box;
      column-gap: 12px;
      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        z-index: -1;
      }
      &::before {
        background: linear-gradient(90deg, #164E9B, #007EB7);
      }
      &::after {
        background: linear-gradient(90deg, #168A9B, #009BB7);
        transition: $transition-base;
      }
      .icon_mail {
        width: 29px;
        fill: #fff;
      }
      span {
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        letter-spacing: .12em;
        transition: $transition-base;
      }
      &:hover::after {
        opacity: 0;
      }
    }
    @include sp {
      margin-block: 18px 17px;
      width: 100%;
      li + li {
        margin-top: 8px;
      }
      a {
        column-gap: 8px;
        justify-content: center;
        height: 45px;
        .icon_mail {
          width: 20px;
        }
      }
    }
  }

  &__copyright {
    @include media-up(lg) {
      display: none;
    }
    @include media-down(lg) {
      font-size: $font-size-xs;
      color: tint($body-color, 50%);
    }
  }
}

.rwd002-gnav-sublist {
  // sitemap.phpなどのシステムメニュー
  list-style: none;
  padding: 0;
  @include media-up(lg) {
    display: none;
  }
  @include media-down(lg) {
    display: flex;
    flex-wrap: wrap;
    margin: map-get($spacers, 2) 0;
    color: tint($body-color, 50%);
    font-size: $font-size-xs;
    margin-left: -0.5em;
    margin-right: -0.5em;
    &__item{
      position: relative;
      padding: 0.2em 0.6em;
    }
    &__item + &__item:before{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%,-50%);
      content: "";
      height: 1em;
      width: 1px;
      background-color: tint($body-color, 50%);
    }
  }
}

// グローバルナビ　Item(親)
.rwd002-gnav-item {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9375rem;
  letter-spacing: 0.14em;
  text-indent: 0.14em;
  font-feature-settings: "palt";

  @include media-down(lg) {
    border: solid $gray-300;
    border-width: 1px 0;
    & + & {
      border-top-width: 0;
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;
    transition: $transition-base;
    padding: 0.25em 15px;
    @include pc {
      min-height: 100px;
      justify-content: center;
    }
    
    @include media-down(lg) {
      height: 100%;
      padding: 10px 0 10px 20px;
      flex-grow: 1;
      flex-basis: calc(100% - 50px);
      @include max(380px){
        span {
          font-size: 14px;
        }
      }
    }

    &:hover,
    &:focus,
    &.current {
      color: $primary;
      text-decoration: none;
    }

    // PC表示のメニュー装飾
    @include media-up(lg) {
      span {
        position: relative;
        padding-block: 10px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: .12em;
        line-height: 1;
        color: #fff;
        transition: $transition-base;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% - 6px);
          height: 1px;
          background-color: #fff;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          transition: transform .2s;
          transform: scaleX(0);
          transform-origin: right;
        }
      }
      &:hover, &.current {
        span {
          &::before {
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      }
    }

    // SP表示のメニュー装飾
    @include media-down(lg) {
      &:after {
        position: absolute;
        content: "";
        top: calc(50% - 0.3em);
        left: 0;
        width: 1.5em;
        height: 0.6em;
        background: center / contain no-repeat;
        background-image: svgUrlFunc(
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$body-color}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
        );
      }
    }
  }
}

@include pc {
  // PC縮小時
  @include max(1050px){
    .rwd002-gnav {
      &__inner {
        column-gap: clamp(10px, 2vw, 30px);
      }
      &-item__link {
        padding-inline: max(.1vw, 7px);
        span {
          font-size: max(1.1vw, 13px);
        }
      }
      &__contact {
        width: clamp(130px, 15vw, 188px);
        a {
          column-gap: 7px;
          .icon_mail {
            width: clamp(15px, 2vw, 23px);
          }
          span {
            font-size: max(1.1vw, 13px);
          }
        }
      }
    }
  }

  // お知らせ等白ヘッダー
  .is--news, .is--contact, .is--privacy, .is--sitemap {
    .rwd002-gnav-item__link span {
      color: $font-color;
      &::before {
        background-color: $font-color;
      }
    }
  }
}

@function svg-right-angle($theme: $primary) {
  @return svgUrlFunc(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
  );
}

@function svg-bottom-angle($theme: $body-color) {
  @return svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>');
}

.btn-icon-right-angle {
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: calc(50% - 0.3em);
    right: 1em;
    width: 1em;
    height: 0.6em;
    background: center / contain no-repeat;
    background-image: svg-right-angle();
  }
  &:hover:after,&:focus:after{
    background-image: svg-right-angle($white);
  }
}

.btn-icon-right-angle-white{
  @extend .btn-icon-right-angle;
  &:after{
    background-image: svg-right-angle($white);
  }
}

.btn-icon-bottom-angle {
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: calc(50% - 0.3em);
    right: 0;
    width: 2em;
    height: 0.6em;
    transition: all .5s ease-in-out;
    background: center / contain no-repeat;
    background-image: svg-bottom-angle();
    transform: rotateX(180deg);
  }
  &.collapsed:after{
    transform: rotateX(0deg);
  }
}

@mixin top_btn() {
  display: flex;
  align-items: center;
  position: relative;
  width: min(100%, 333px);
  height: 57px;
  box-sizing: border-box;
  padding-left: 30px;
  clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
  z-index: 1;
  .text {
    color: #fff;
    @include Mulish(600);
    font-size: 14px;
    letter-spacing: .14em;
  }
  .arrow {
    width: 34px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    z-index: -1;
  }
  &::before {
    background: linear-gradient(90deg, #007EB7, #168A9B);
  }
  &::after {
    background: linear-gradient(90deg, #164E9B, #007EB7);
    transition: $transition-base;
  }

  &:hover {
    .arrow {
      transform: translateX(5px);
    }
    &::after {
      opacity: 0;
    }
  }

  @include sp {
    justify-content: center;
    padding-inline: 0 10px;
    margin-inline: auto;
  }
}

@mixin broslink() {
  display: grid;
  place-content: center;
  position: relative;
  width: 100%;
  height: 80px;
  border: 1px solid $theme-blue;
  transition: $transition-base;
  z-index: 1;
  overflow: hidden;
  .text {
    color: $theme-blue;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .2em;
  }
  .arrow {
    width: 28px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    &::before, &::after {
      background-color: $theme-blue;
    }
  }
  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    z-index: -1;
  }
  &::before {
    background: linear-gradient(90deg, #164E9B, #007EB7);
  }
  &::after {
    background: #fff;
    transition: $transition-base;
  }

  &:hover, &.current {
    border-color: transparent;
    .text {
      color: #fff;
    }
    .arrow {
      &::before, &::after {
        background-color: #fff;
      }
      transform: translateX(5px);
    }
    &::after {
      opacity: 0;
    }
  }

  @include sp {
    height: 48px;
    .text {
      font-size: 15px;
    }
  }
}

.banner_link {
  display: grid;
  place-content: center;
  place-items: center;
  position: relative;
  row-gap: 10px;
  width: 100%;
  height: 250px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  z-index: 1;
  picture {
    position: absolute;
    display: block;
    inset: 0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: $transition-base;
    }
  }
  strong, small {
    line-height: 1;
    color: #fff;
  }
  strong {
    @include Jost(300);
    font-size: 55px;
    letter-spacing: .12em;
  }
  small {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .14em;
  }
  .arrow {
    width: 27px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &:hover {
    img {
      transform: scale(1.07);
    }
    .arrow {
      transform: translateX(5px);
    }
  }

  @include sp {
    row-gap: 8px;
    height: 130px;
    strong {
      font-size: 40px;
    }
    small {
      font-size: 14px;
      letter-spacing: .1em;
    }
  }
}

.map_link {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  max-inline-size: max-content;
  i {
    font-size: 15px;
    color: #fff
  }
  .text {
    @include Mulish(600);
    font-size: 14px;
    letter-spacing: .14em;
    color: #fff;
    line-height: 1;
    margin-left: 6px;
  }
  .arrow {
    width: 25px;
    margin-left: 10px;
  }

  &:hover .arrow {
    transform: translateX(5px);
  }
}
.anchorlink_list {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  li {
    width: min(100%, 197px);
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    height: 30px;
    border: 1px solid #C3C4C4;
    background-color: #F7F7F7;
    border-radius: 15px;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .14em;
    img {
      width: 6px;
      transition: $transition-base;
    }
    &:hover {
      opacity: .7;
      img {
        transform: translateY(3px);
      }
    }
  }
  
  @include sp {
    margin-inline: 13px;
    a {
      height: 30px;
      border-radius: 15px;
      column-gap: 6px;
    }
  }
}
.rwd002-breadcrumbs {
  display: flex;
  padding-inline: 30px;
  margin-block: 90px 24px;
  &__list {
    @extend .breadcrumb;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    row-gap: 6px;
  }
  &__item {
    font-size: 11px;
    line-height: 1;
    color: #504443;
    letter-spacing: 0;
    & + &{
      padding-left: 0;
    }
    & + &::before {
      content: ">";
      display: inline-block;
      letter-spacing: 0;
      margin-inline: 7px 5px;
      position: relative;
      bottom: 1px;
    }
    a, span {
      font-size: 11px;
      letter-spacing: .18em;
      line-height: 1;
      color: #504443;
    }
    a:hover {
      opacity: .7;
    }
  }

  @include sp {
    margin-block: 80px 55px;
    padding-inline: 20px;
    &__item {
      & + &{
        padding-left: 1px;
      }
      & + &::before {
        margin-left: 4px;
      }
    }
  }
}